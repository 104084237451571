<article *ngIf="post()" class="container post" style="flex-grow: 1">
    <h1>{{ post().title }}</h1>

    <div class="post-details">
        <span class="post-author">by Stephen Fluin</span>
        <a
            href="https://twitter.com/intent/follow?screen_name=StephenFluin&tw_p=followbutton"
            style="
                color: white;
                font-size: 11px;
                border-radius: 3px;
                display: inline-flex;
                align-items: center;
                padding: 0 6px;
                background-color: #1da1f2;
            "
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" style="width: 20px">
                <defs>
                    <style>
                        .cls-1 {
                            fill: #1da1f2;
                        }
                        .cls-2 {
                            fill: #fff;
                        }
                        .cls-3 {
                            fill: none;
                        }
                    </style>
                </defs>
                <title>Twitter_Logo_White-on-Blue</title>
                <g id="Dark_Blue" data-name="Dark Blue"><rect class="cls-1" width="400" height="400" /></g>
                <g id="Logo_FIXED" data-name="Logo — FIXED">
                    <path
                        class="cls-2"
                        d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23"
                    />
                    <rect class="cls-3" width="400" height="400" />
                </g>
            </svg>
            &#64;StephenFluin</a
        >
        <span class="post-date date">{{ post().date }}</span>
    </div>

    <div *ngIf="adminService.isAdmin">
        <a [routerLink]="['/admin/', post().id]">Edit This Post</a>
    </div>

    <div class="highlight-image">
        <img *ngIf="!post().image" src="/assets/images/imgpostholder.png" [alt]="post().title" />
        <img *ngIf="post().image" [src]="post().image" [alt]="post().title" />
    </div>

    <div [innerHTML]="post().renderedBody" class="post-content"></div>
</article>
