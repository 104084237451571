<div class="bio">
    <div class="content">
        <h1>Stephen Fluin</h1>
        <p>
            Stephen is Head of Developer Relations for Axelar. Stephen builds Developer Relations teams that solve real world problems faced by developers and businesses, and advocates for the needs of the community.
        </p>
        <p>
            With 22 years in the Software Development industry, Stephen has served as an executive technologist, consultant, and entrepreneur at Google, Trello, Chainlink Labs, and more.
        </p>
        <p>
            Stephen applies deep technical knowledge and business acumen to accelerate software development. As an avid fan of wearables and the decentralized internet, he frequently collaborates with businesses and developers in the community.
        </p>
        <br>
    </div>
</div>