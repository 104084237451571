<!-- header-->
<div class="padded" id="header">
    <div>
        <a routerLink="/" class="logo">
            fluin.io
        </a>
    </div>
    <div class="spacer"></div>
    <nav>
        <a routerLink="/bio" routerLinkActive="active-link">Bio</a>
        <a href="https://www.youtube.com/playlist?list=PLkZLBHjxfgssv4X0Bcw6I9O4s5XBUvX5x">Talks</a>
        <a href="https://twitter.com/stephenfluin" target="_blank" rel="noopener me">
            <img class="social-icon" alt="@stephenfluin on twitter" src="/assets/images/twitter.svg" />
        </a>
        <a href="https://developers.live/@StephenFluin" target="_blank" rel="noopener me">
            <img class="social-icon" alt="Stephen Fluin on Mastodon" src="/assets/images/mastodon.svg" />
        </a>
    </nav>
</div>
<!-- end header-->
