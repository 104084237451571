<!-- intro-block-->
<div id="intro-block">
    <img src="/assets/images/mainpic.jpg" alt="Stephen Fluin" class="padded" />
    <div>
        <div>
            <h1>Stephen Fluin</h1>
        </div>
        <div>
            <p style="font-weight: bold">I make developers more effective.</p>
            <br />
            <p>
                I combine a deep understanding of modern technology and business practices to drive great software
                products, strategies, and experiences.
            </p>
            <br />

            <p>
                I love wearables and the decentralized internet. I love videography, flying drones, building
                apps that solve my own problems, and traveling the world.
            </p>
        </div>
    </div>
</div>
<!-- end intro-block-->

<hr />
<post-list [limit]="64"></post-list>
